<template>
    <header>
        <nav class="container navbar navbar-expand-lg">
            <div class="left-header">
                <router-link :hreflang="`${$i18n.locale}`" class="navbar-brand" :to="`/${$i18n.locale}`">
                    <img src="../assets/img/koff-logo.svg" class="d-inline-block align-top"
                         v-bind:alt="$t('home.description')">
                </router-link>
            </div>

            <div class="ml-auto d-lg-none">
                <LanguageSwitcher />
            </div>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-line"></span>
                <span class="navbar-toggler-line"></span>
                <span class="navbar-toggler-line"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-md-end" id="navbarNavAltMarkup">
                <div class="navbar-nav">
                    <router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}`" class="nav-item nav-link active">{{ $t('navbar.home').toUpperCase() }}
                    </router-link>
                    <router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/about-us`" class="nav-item nav-link">{{ $t('navbar.about-us').toUpperCase() }}
                    </router-link>
                    <!--<router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`" class="nav-item nav-link">{{ $t('navbar.products').toUpperCase() }}</router-link>-->
                    <router-link :hreflang="`${$i18n.locale}`" :to="{name: 'Brands', params:{lang: $i18n.locale, brand: 'spigen'}}"
                                 class="nav-item nav-link">{{ $t('navbar.products').toUpperCase() }}
                    </router-link>
                    <a href="https://shop.koff.ro/login" class="nav-item nav-link" target="_blank">{{ $t('navbar.login').toUpperCase() }}</a>
                    <div class="d-none d-lg-block">
                        <LanguageSwitcher/>
                    </div>

                    <a href="https://shop.koff.ro/register" class="nav-item nav-link" target="_blank">{{ $t('navbar.distributor').toUpperCase() }}</a>
                </div>
            </div>
        </nav>

    </header>
</template>

<script>
    import LanguageSwitcher from "./LanguageSwitcher";

    export default {
        name: 'TheHeader',
        components: {
            LanguageSwitcher
        },
    }

</script>

<style lang="scss" scoped>
    .left-header {
        display: flex;
        align-items: center;
    }

    .right-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
