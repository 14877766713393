<template>
    <footer>
        <div class="container">
            <div class="footer-content">
                <ul class="footer-links">
                    <li>
                        <div class="footer-title">{{ $t('footer.business.title').toUpperCase() }}</div>
                    </li>
                    <li>{{ $t('footer.business.name').toUpperCase() }}</li>
                    <li>{{ $t('footer.business.cui') }}</li>
                    <li><a href="mailto: vanzari@koff.ro">{{ $t('footer.business.email') }}@koff.ro</a></li>
                    <li>
                        <a v-if="$i18n.locale === 'ro'" href="tel:0774596571">{{ $t('footer.business.tel') }}</a>
                        <a v-else href="tel:+40775143053">{{ $t('footer.business.tel') }}</a>
                    </li>
                </ul>
                <ul class="footer-links">
                    <li>
                        <div class="footer-title">{{ $t('footer.categories.title').toUpperCase() }}</div>
                    </li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`">{{ $t('footer.categories.row-1') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`">{{ $t('footer.categories.row-2') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`">{{ $t('footer.categories.row-3') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`">{{ $t('footer.categories.row-4') }}</router-link></li>
                </ul>
                <ul class="footer-links">
                    <li>
                        <div class="footer-title">{{ $t('footer.information.title').toUpperCase() }}</div>
                    </li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/privacy-policy`">{{ $t('footer.information.privacy') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/cookie-policy`">{{ $t('footer.information.cookies') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/delivery`">{{ $t('footer.information.delivery') }}</router-link></li>

                </ul>
                <ul class="footer-links">
                    <li>
                        <div class="footer-title">{{ $t('footer.useful.title').toUpperCase() }}</div>
                    </li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/about-us`">{{ $t('footer.useful.about') }}</router-link></li>
                    <li><router-link :hreflang="`${$i18n.locale}`" :to="`/${$i18n.locale}/brands/techsuit`">{{ $t('footer.useful.products') }}</router-link></li>
                    <li><a href="https://shop.koff.ro" target=”_blank”>{{ $t('footer.useful.account') }}</a></li>
                    <li><a href="https://shop.koff.ro/register" target=”_blank”>{{ $t('footer.useful.distributor') }}</a></li>
                </ul>

            </div>
            <div class="separator">
                <hr>
                <hr>
            </div>
            <div class="copyright">
                <img src="../assets/img/koff-logo.svg" alt="Logo KOFF">
                <p>KOFF DISTRIBUTION SRL © 2016. {{ $t('footer.copyright') }}.</p>
            </div>
        </div>
    </footer>
</template>

<script>
</script>

<style scoped lang="scss">

    .footer-links li a {
        text-decoration: none;
    }

</style>
